'use client';

import {ErrorBoundary as HighlightErrorBoundary} from '@highlight-run/next/client';

export default function ErrorPage({error, reset}: {error: Error; reset: () => void}) {
  const isLocalhost = typeof window === 'object' && window.location.host?.startsWith('localhost');

  return (
    <HighlightErrorBoundary showDialog={!isLocalhost}>
      <div className="flex flex-col h-screen justify-center items-center gap-2">
        <h2>Something went wrong!</h2>

        <button type="button" onClick={() => reset()}>
          Try again
        </button>
      </div>
    </HighlightErrorBoundary>
  );
}
